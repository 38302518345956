import React, { useContext } from "react";
import { SectionTitle } from "../../core/SectionTitle";
import { Translation } from "../../core/Translation";
import { Language } from "../../infrastructure/Languages";

export const EditArtworkContact = ({
    register,
    getErrorMessage,
    errors,
}) => {

    const language = useContext(Language);

    return (
        <div>
            <div className="input-validation">
                <SectionTitle required>
                    <Translation
                        id="8eb7936c-b737-4f03-b8d8-e282d9f43c6a"
                        defaultMessage="First Name"
                        category="Label"
                    />
                </SectionTitle>
                <input
                    placeholder={language.translateLabel('8eb7936c-b737-4f03-b8d8-e282d9f43c6a', 'First Name', 'Label')}
                    className={"control textbox"}
                    {...register("artworkContact.firstName", {
                        required: language.translateLabel('c57869b9-9bb1-48cf-b261-66341d5267f8', 'First name is required', 'Label'),
                        maxLength: {
                            value: 255,
                            message: language.translateLabel('d3052fdc-78fd-48a4-ace8-b54d9809a1b7', 'First name must be less than {0} characters', 'Label', { 0 : 255})
                        },
                        validate: (value) => value.trim() !== '' || 'This field cannot be empty'
                    })}
                />
                {getErrorMessage(errors, "artworkContact.firstName") && (
                    <span className="error-message">
                        {getErrorMessage(errors, "artworkContact.firstName")?.message}
                    </span>
                )}
            </div>

            <div className="input-validation">
                <SectionTitle required>
                    <Translation
                        id="cedc02f5-9290-4fa0-9107-c052919a958c"
                        defaultMessage="Last Name"
                        category="Label"
                    />
                </SectionTitle>
                <input
                    placeholder={language.translateLabel('cedc02f5-9290-4fa0-9107-c052919a958c', 'Last Name', 'Label')}
                    className={"control textbox"}
                    {...register("artworkContact.lastName", {
                        required: language.translateLabel('f1eba0e4-fcdb-4f15-8135-6e9873595ad2', 'Last name is required', 'Label'),
                        maxLength: {
                            value: 255,
                            message: language.translateLabel('5a93bf48-5ec2-4d9a-a5d0-b4f0a0942868', 'Last name must be less than {0} characters', 'Label', { 0 : 255 })
                        },
                        validate: (value) => value.trim() !== '' || 'This field cannot be empty'
                    })}
                />
                {getErrorMessage(errors, "artworkContact.lastName") && (
                    <span className="error-message">{getErrorMessage(errors, "artworkContact.lastName")?.message}</span>
                )}
            </div>

            <div className="input-validation">
                <SectionTitle required>
                    <Translation id='8c80e88f-b776-4497-bb1d-e76b8894e1df' defaultMessage='Artwork Contact Email' category='Label' />
                </SectionTitle>
                <input
                    placeholder={language.translateLabel('15b53b85-df5e-46db-a112-10950c5dfe37', 'E-mail', 'Label')}
                    className={"control textbox"}
                    {...register("artworkContact.email", {
                        required: { value: true, message: language.translateLabel('32c9d8f7-60b1-4865-872d-bbb09ba39f07', 'E-mail address is required', 'Label') },
                        pattern: {
                            value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                            message: language.translateLabel('fb9a4bd8-8b6e-4bfb-abad-b917a113320c', 'E-mail address is not correctly formatted', 'Label')
                        },
                    })}
                />
                {getErrorMessage(errors, "artworkContact.email") && (
                    <span className="error-message">{getErrorMessage(errors, "artworkContact.email")?.message}</span>
                )}
            </div>

            <div className="input-validation">
                <SectionTitle required>
                    <Translation id="6c280ccd-1c9a-403e-afb1-b8a413da45bd" defaultMessage="Phone" category="Label" />
                </SectionTitle>
                <input
                    placeholder={language.translateLabel('6c280ccd-1c9a-403e-afb1-b8a413da45bd', 'Phone', 'Label')}
                    className={"control textbox"}
                    {...register("artworkContact.phone", {
                        required: {
                            value: true,
                            message: language.translateLabel('8803f7d0-7275-4649-9b46-59b909d57c68', 'Phone Number is required', 'Label'),
                        },
                        pattern: {
                            value: /^[0-9-]+$/,
                            message: language.translateLabel('f5bafeab-39fd-4be7-82ce-72fd8a77816c', 'Phone number must contain only numbers', 'Label')
                        },
                    })}
                />
                {getErrorMessage(errors, "artworkContact.phone") && (
                    <span className="error-message">{getErrorMessage(errors, "artworkContact.phone")?.message}</span>
                )}
            </div>

        </div>
    );
};
