import React, { useContext, useState, clearErrors } from "react";
import { Row } from "../../core/Row";
import { Column } from "../../core/Column";
import { Card } from "../../core/Card";
import { Alert } from "../../core/Alert";
import { Translation } from "../../core/Translation";
import { SectionTitle } from "../../core/SectionTitle";
import { Language } from "../../infrastructure/Languages";
import { useForm, get, useFieldArray, useWatch, Controller, useFormState } from "react-hook-form";
import { useEffect } from "react";
import "./HookEditAddress.css";
import "./AdditionalShippingAddress.css";
import { Edit, Edit2, Edit3, Trash, Trash2 } from "react-feather";

export const AdditionalShippingAddress = ({
    addressType,
    countries,
    unregister,
    getValues,
    setValue,
    register,
    countriesForStateValidation,
    getErrorMessage,
    requireCompanyName,
    orderPlacementApi,
    trigger,
    addressIndex,
    rowEditingIndex,
    resetField,
    updateRowEditingIndex,
    addingIndex,
    updateAddingIndex,
    salesforceShippingAddresses,
    removeItem,
    control,
    watch,
}) => {
    const language = useContext(Language);
    const { errors } = useFormState({ control });

    const [countryHasStateSelection, setCountryHasStateSelection] = useState(false);
    const [states, setStates] = useState(null);
    const [countryStateConfig, setCountryStateConfig] = useState(null);
    const [isEditing, setIsEditing] = useState(false);

    const getStatesByCountry = async (countryId) => {
        var result = await orderPlacementApi.getStatesByCountry(countryId);
        return result?.response;
    };
    const isAdding = addingIndex === addressIndex;

    const loadCountrySubSelection = async (countryName) => {
        var countryStateConfig = countriesForStateValidation.filter((x) => x.country === countryName)[0];

        setCountryStateConfig(countryStateConfig);
        var hasCountryForStateReload = !!countryStateConfig;
        setCountryHasStateSelection(hasCountryForStateReload);

        var lookup = getCountryLookup(countries);
        var countryId = lookup.get(countryName);

        if (hasCountryForStateReload) {
            console.log("get states for country, state", countryName, getValues(`${addressType}.${addressIndex}`));
            var statesForCountry = await getStatesByCountry(countryId);
            setStates(statesForCountry);

            console.log("Loaded states for country change state count", statesForCountry.length);
        } else {
            unregister(`${addressType}.${addressIndex}.state`);
            console.log("HookEditAdditionalShippingAddress state was unregistered");
        }
    };

    const getCountryLookup = (countries) => {
        var lookup = new Map();

        countries.forEach((x) => {
            lookup.set(x.value, x.key);
        });
        return lookup;
    };

    const getControlClassName = (field) => {
        return `control textbox ${
            getErrorMessage(errors, `${addressType}.${addressIndex}.${field}`)?.message ? "error" : null
        }`;
    };

    const setAddressToSalesforceAddress = (address) => {
        //console.log(e);
        //console.log(getValues('additionalShippingAddress.salesforceLocationId'));

        var matchingAddress = salesforceShippingAddresses.find((x) => x.salesforceLocationId === address);
        console.log(matchingAddress);

        function setValueWithNewDefault(name, value) {
            resetField(name, { defaultValue: value });
        }

        setValueWithNewDefault(`${addressType}.${addressIndex}.accountId`, matchingAddress.accountId);
        setValueWithNewDefault(`${addressType}.${addressIndex}.name`, matchingAddress.name);
        setValueWithNewDefault(`${addressType}.${addressIndex}.addressLine1`, matchingAddress.addressLine1);
        setValueWithNewDefault(`${addressType}.${addressIndex}.addressLine2`, matchingAddress.addressLine2);
        setValueWithNewDefault(`${addressType}.${addressIndex}.city`, matchingAddress.city);
        setValueWithNewDefault(`${addressType}.${addressIndex}.state`, matchingAddress.state);
        setValueWithNewDefault(`${addressType}.${addressIndex}.country`, matchingAddress.country);
        setValueWithNewDefault(`${addressType}.${addressIndex}.postalCode`, matchingAddress.postalCode);
        setValueWithNewDefault(
            `${addressType}.${addressIndex}.specialInstructions`,
            matchingAddress.specialInstructions
        );
        setValueWithNewDefault(
            `${addressType}.${addressIndex}.salesforceLocationId`,
            matchingAddress.salesforceLocationId
        );
    };

    const cancelAdd = () => {
        console.log("cancel issued for", addressIndex);

        function reset(name) {
            resetField(name, {
                keepDirty: true,
            });
        }

        if (isEditing) {
            reset(`additionalShippingAddresses.${addressIndex}.name`);
            reset(`additionalShippingAddresses.${addressIndex}.addressLine1`);
            reset(`additionalShippingAddresses.${addressIndex}.addressLine2`);
            reset(`additionalShippingAddresses.${addressIndex}.city`);
            reset(`additionalShippingAddresses.${addressIndex}.country`);
            reset(`additionalShippingAddresses.${addressIndex}.state`);
            reset(`additionalShippingAddresses.${addressIndex}.postalCode`);
        }

        if (isAdding) {
            removeItem(addingIndex);
        }

        setIsEditing(false);
        resetAddingEditIndexes();
    };

    const resetAddingEditIndexes = () => {
        updateRowEditingIndex(-1);
        updateAddingIndex(-1);
    };

    const addressWatch = watch(`${addressType}.${addressIndex}`);

    const addEditShippingAddress = async () => {
        console.log("DEBUG >> triggering validation, isAdding, addressIndex", isAdding, addressIndex);

        await trigger(
            [
                `additionalShippingAddresses.${addressIndex}.name`,
                `additionalShippingAddresses.${addressIndex}.addressLine1`,
                `additionalShippingAddresses.${addressIndex}.city`,
                `additionalShippingAddresses.${addressIndex}.country`,
                `additionalShippingAddresses.${addressIndex}.state`,
                `additionalShippingAddresses.${addressIndex}.postalCode`,
            ],
            {
                shouldFocus: true,
            }
        );

        console.log(errors);
        if (errors?.additionalShippingAddresses != null && errors.additionalShippingAddresses[addressIndex] != null) {
            return;
        }

        resetAddingEditIndexes();
        setIsEditing(false);
    };

    const renderAddEditRow = () => {
        var isEditing = rowEditingIndex === addressIndex;
        //var isAdding = addingIndex === addressIndex;
        // console.log(
        //     "DEBUG >> isEditing, isAdding, rowEditingIndex, index, addingIndex",
        //     isEditing,
        //     isAdding,
        //     rowEditingIndex,
        //     addressIndex,
        //     addingIndex,
        //     getValues(`${addressType}.${addressIndex}.name`)
        // );

        if (!isEditing && !isAdding) {
            return (
                <div>
                    <Row className="additional-shipping-address-row">
                        {/* // key={"shipaddr-row" + item.id}> */}
                        <div className="additional-shipping-address-row-text">
                            <span className="address">
                                {" "}
                                {addressIndex + 1}. {addressWatch.name}, {addressWatch.addressLine1}
                                {addressWatch.addressLine2 == null || addressWatch.addressLine2 === ""
                                    ? null
                                    : " " + addressWatch.addressLine2}
                                , {addressWatch.city}, {addressWatch.state}, {addressWatch.postalCode},{" "}
                                {addressWatch.country}
                            </span>
                        </div>
                        {rowEditingIndex !== -1 ? (
                            <div></div>
                        ) : (
                            <>
                                <div className="additional-shipping-address-row-buttons">
                                    <Trash2
                                        className="icon"
                                        onClick={() => {
                                            removeItem(addressIndex);
                                            updateRowEditingIndex(-1);
                                        }}
                                    >
                                        Remove
                                    </Trash2>
                                </div>
                                <div className="additional-shipping-address-row-buttons">
                                    <Edit
                                        className="icon"
                                        onClick={async () => {
                                            updateRowEditingIndex(addressIndex);
                                            var country = getValues(`${addressType}.${addressIndex}.country`);
                                            console.log("Edit with country", country);
                                            await loadCountrySubSelection(country);
                                            setIsEditing(true);
                                        }}
                                    >
                                        Edit
                                    </Edit>
                                </div>
                            </>
                        )}
                    </Row>
                </div>
            );
        }

        return (
            <div className={rowEditingIndex === -1 ? "" : "additionalShippingAddress"}>
                {isAdding ? (
                    <Row>
                        <select
                            data-testid={"additional-shiping-address"}
                            {...register(`${addressType}.${addressIndex}.salesforceLocationId`, {
                                onChange: async (e) => {
                                    var matchingAddress = salesforceShippingAddresses.find(
                                        (x) => x.salesforceLocationId === e.target.value
                                    );
                                    setValue(`${addressType}.${addressIndex}.state`, matchingAddress.state);
                                    await loadCountrySubSelection(matchingAddress.country);
                                    setAddressToSalesforceAddress(e.target.value);
                                },
                            })}
                            className="control dropdown"
                        >
                            <option key={0} value={""}>
                                {language.translateLabel(
                                    "b95b4de0-66b7-4217-8d03-3ba745d495aa",
                                    "Select an available shipping address",
                                    "Label"
                                )}
                            </option>
                            {salesforceShippingAddresses.map((item, i) => {
                                return (
                                    <option key={i + 1} value={item.salesforceLocationId}>
                                        {item.name}, {item.addressLine1}, {item.city}, {item.state}, {item.postalCode},{" "}
                                        {item.country}
                                    </option>
                                );
                            })}
                        </select>
                    </Row>
                ) : (
                    ""
                )}
                <Row>
                    <Column>
                        <div className="input-validation">
                            <SectionTitle required>
                                <Translation
                                    id="c6e595bb-34a8-4047-b9c9-8054b15c00b2"
                                    defaultMessage="Country"
                                    category="Label"
                                />
                            </SectionTitle>
                            <Controller
                                control={control}
                                name={`${addressType}.${addressIndex}.country`}
                                rules={{
                                    required: {
                                        value: true,
                                        message: (
                                            <Translation
                                                id="9db65cd0-6ee3-4af2-b435-bacdb8d7b4db"
                                                defaultMessage="Please select a country"
                                                category="Label"
                                            />
                                        ),
                                    },
                                }}
                                render={({ field }) => (
                                    <select
                                        key={field.id}
                                        data-testid={"order-placement-" + addressType + "-country"}
                                        //defaultValue={getValues(`${addressType}.${addressIndex}.country`)}
                                        className="control dropdown"
                                        value={field.value}
                                        onChange={async (e) => {
                                            console.log("onchange controller country", field.value, e.target.value);
                                            field.onChange(e.target.value);
                                            await loadCountrySubSelection(e.target.value);
                                        }}
                                    >
                                        <option key={0} value={""}>
                                            {language.translateLabel(
                                                "0eed91bc-2f44-4440-aedf-8be50856ae42",
                                                "Please select...",
                                                "Label"
                                            )}
                                        </option>
                                        {countries.map((item, i) => {
                                            return (
                                                <option key={item.key} value={item.value}>
                                                    {item.value}
                                                </option>
                                            );
                                        })}
                                    </select>
                                )}
                            />
                            {getErrorMessage(errors, `${addressType}.${addressIndex}.country`) && (
                                <span className="error-message">
                                    {getErrorMessage(errors, `${addressType}.${addressIndex}.country`)?.message}
                                </span>
                            )}
                        </div>
                    </Column>
                    <Column>
                        {requireCompanyName === true && (
                            <div className="input-validation">
                                <SectionTitle required>
                                    <Translation
                                        id="452cf8ff-b390-4b5f-98fa-23a3286f9c60"
                                        defaultMessage="Name"
                                        category="Label"
                                    />
                                </SectionTitle>
                                <input
                                    data-testid={"order-placement-" + addressType + "-name"}
                                    type="text"
                                    className={getControlClassName("name")}
                                    placeholder={language.translateLabel('452cf8ff-b390-4b5f-98fa-23a3286f9c60', 'Name', 'Label') }
                                    {...register(`${addressType}.${addressIndex}.name`, {
                                        required: {
                                            value: true,
                                            message: (
                                                <Translation
                                                    id="b69cc6b7-4904-4d2e-8126-755436830d51"
                                                    defaultMessage="Please enter a name"
                                                    category="Label"
                                                />
                                            ),
                                        },
                                        maxLength: 50,
                                    })}
                                />
                                {getErrorMessage(errors, `${addressType}.${addressIndex}.name`) && (
                                    <span className="error-message">
                                        {getErrorMessage(errors, `${addressType}.${addressIndex}.name`)?.message}
                                    </span>
                                )}
                            </div>
                        )}
                    </Column>
                </Row>
                <Row>
                    <Column>
                        <div className="input-validation">
                            <SectionTitle required>
                                <Translation
                                    id="04ea4b06-699e-497d-af8b-5843fb832290"
                                    defaultMessage="Address Line 1"
                                    category="Label"
                                />
                            </SectionTitle>
                            <input
                                data-testid={"order-placement-" + addressType + "-addressLine1"}
                                type="text"
                                className={getControlClassName("addressLine1")}
                                placeholder={language.translateLabel('04ea4b06-699e-497d-af8b-5843fb832290', 'Address Line 1', 'Label')}
                                {...register(`${addressType}.${addressIndex}.addressLine1`, {
                                    required: {
                                        value: true,
                                        message: (
                                            <Translation
                                                id="b14d4662-5838-4516-881f-2a142ecb7845"
                                                defaultMessage="Please enter an address"
                                                category="Label"
                                            />
                                        ),
                                    },
                                    maxLength: 100,
                                })}
                            />
                            {getErrorMessage(errors, `${addressType}.${addressIndex}.addressLine1`) && (
                                <span className="error-message">
                                    {getErrorMessage(errors, `${addressType}.${addressIndex}.addressLine1`)?.message}
                                </span>
                            )}
                        </div>
                    </Column>
                    <Column>
                        <div className="input-validation">
                            <SectionTitle>
                                <Translation
                                    id="91ce24b8-736a-4bac-9b50-1cec9a24f3b2"
                                    defaultMessage="Address Line 2"
                                    category="Label"
                                />
                            </SectionTitle>
                            <input
                                data-testid={"order-placement-" + addressType + "-addressLine2"}
                                type="text"
                                className="control textbox"
                                placeholder={language.translateLabel('91ce24b8-736a-4bac-9b50-1cec9a24f3b2', 'Address Line 2', 'Label')}
                                {...register(`${addressType}.${addressIndex}.addressLine2`, {
                                    maxLength: 50,
                                })}
                            />
                            {getErrorMessage(errors, `${addressType}.${addressIndex}.addressLine2`) && (
                                <span className="error-message">
                                    {getErrorMessage(errors, `${addressType}.${addressIndex}.addressLine2`)?.message}
                                </span>
                            )}
                        </div>
                    </Column>
                </Row>
                <Row>
                    <Column>
                        <div className="input-validation">
                            <SectionTitle required>
                                <Translation
                                    id="76f3a034-2c36-4f58-b2e3-573cc204a5d3"
                                    defaultMessage="City"
                                    category="Label"
                                />
                            </SectionTitle>
                            <input
                                data-testid={"order-placement-additionalShippingAddress-city"}
                                type="text"
                                className={getControlClassName("city")}
                                placeholder={language.translateLabel('76f3a034-2c36-4f58-b2e3-573cc204a5d3', 'City', 'Label')}
                                {...register(`${addressType}.${addressIndex}.city`, {
                                    required: {
                                        value: true,
                                        message: (
                                            <Translation
                                                id="7d2ce4a4-5ce3-45e8-858c-d3844f4880ef"
                                                defaultMessage="Please enter a city"
                                                category="Label"
                                            />
                                        ),
                                    },
                                    maxLength: 30,
                                })}
                            />
                            {getErrorMessage(errors, `${addressType}.${addressIndex}.city`) && (
                                <span className="error-message">
                                    {getErrorMessage(errors, `${addressType}.${addressIndex}.city`)?.message}
                                </span>
                            )}
                        </div>
                    </Column>
                    <Column>
                        {countryHasStateSelection && countryStateConfig && states && states.length > 0 && (
                            <div className="input-validation">
                                <SectionTitle required>
                                    {countryStateConfig.selectionLabel == "State" && (
                                        <Translation
                                            id="f0d8f2b5-38fc-49b7-b802-bdf91885ea5a"
                                            defaultMessage={countryStateConfig.selectionLabel}
                                            category="Label"
                                        />
                                    )}
                                    {countryStateConfig.selectionLabel == "Province" && (
                                        <Translation
                                            id="12b9c440-267d-4a8d-a44b-6205b3011d87"
                                            defaultMessage={countryStateConfig.selectionLabel}
                                            category="Label"
                                        />
                                    )}
                                </SectionTitle>
                                <Controller
                                    control={control}
                                    name={`${addressType}.${addressIndex}.state`}
                                    rules={{
                                        required: {
                                            value: true,
                                            message: (
                                                <Translation
                                                    id="b6b52dea-31fe-4e53-b02f-65244b24e5c9"
                                                    defaultMessage="Please select a state"
                                                    category="Label"
                                                />
                                            ),
                                        },
                                    }}
                                    render={({ field }) => (
                                        <select
                                            key={field.id}
                                            data-testid={"order-placement-" + addressType + "-state"}
                                            className="control dropdown"
                                            onChange={field.onChange}
                                            value={field.value}
                                        >
                                            <option key={0} value={""}>
                                                {language.translateLabel(
                                                    "0eed91bc-2f44-4440-aedf-8be50856ae42",
                                                    "Please select...",
                                                    "Label"
                                                )}
                                            </option>

                                            {states.map((item, i) => {
                                                return (
                                                    <option key={item.key} value={item.value}>
                                                        {item.value}
                                                    </option>
                                                );
                                            })}
                                        </select>
                                    )}
                                />

                                {getErrorMessage(errors, `${addressType}.${addressIndex}.state`) && (
                                    <span className="error-message">
                                        {getErrorMessage(errors, `${addressType}.${addressIndex}.state`)?.message}
                                    </span>
                                )}
                            </div>
                        )}
                    </Column>
                </Row>
                <Row>
                    <Column>
                        <div className="input-validation">
                            <SectionTitle required>
                                <Translation
                                    id="0b842d4e-d67d-47ab-8b36-bf494f7c2fa7"
                                    defaultMessage="Postal Code"
                                    category="Label"
                                />
                            </SectionTitle>
                            <input
                                data-testid={"order-placement-additionalShippingAddress-postalCode"}
                                type="text"
                                className={getControlClassName("postalCode")}
                                placeholder={language.translateLabel('0b842d4e-d67d-47ab-8b36-bf494f7c2fa7', 'Postal Code', 'Label')}
                                {...register(`${addressType}.${addressIndex}.postalCode`, {
                                    required: {
                                        value: true,
                                        message: (
                                            <Translation
                                                id="0b3d4755-7414-4d6f-9793-64585824a677"
                                                defaultMessage="Please enter a postal code"
                                                category="Label"
                                            />
                                        ),
                                    },
                                    maxLength: 20,
                                    validate: {
                                        checkPostalCodeLength: (value) => {
                                            if (
                                                value.length > 5 &&
                                                getValues(`${addressType}.${addressIndex}.country`)?.toLowerCase() ===
                                                    "united states"
                                            ) {
                                                return language.translateLabel(
                                                    "77104189-c086-4377-bb75-bff3c40209d8",
                                                    "Please enter a 5-digit postal code"
                                                );
                                            }
                                            return true;
                                        },
                                    },
                                    onChange: (e) => {
                                        trigger(`${addressType}.${addressIndex}.postalCode`, {
                                            shouldFocus: true,
                                        });
                                    },
                                })}
                            />
                            {getErrorMessage(errors, `${addressType}.${addressIndex}.postalCode`) && (
                                <span className="error-message">
                                    {getErrorMessage(errors, `${addressType}.${addressIndex}.postalCode`)?.message}
                                </span>
                            )}
                        </div>
                    </Column>

                    <Column>
                        <div className="input-validation">
                            <SectionTitle cms="shipping-requirements">
                                <Translation
                                    id="0ebbea5a-b555-4045-9fd6-254f0384054e"
                                    defaultMessage="Other Shipping Requirements"
                                    category="Label"
                                />
                            </SectionTitle>
                            <input
                                data-testid={"order-placement-specialInstructions"}
                                type="text"
                                className="control textbox specialInstructions"
                                placeholder={language.translateLabel('4d553e62-b651-4a31-b1d6-6dc4da336a05', 'Examples: Liftgate or appointment required, contact a person when delivery has arrived, etc.', 'Label')}
                                {...register(`${addressType}.${addressIndex}.specialInstructions`, {
                                    required: {
                                        value: false,
                                    },
                                    maxLength: 250,
                                    validate: {
                                        checkInstructionLength: (value) => {
                                            if (value != null && value.length > 249) {
                                                return language.translateLabel('fde5374f-c5b8-4b7b-bca5-fa6efad645e5', 'Max length for instructions is {0} characters', 'Label', { 0: 250 })
                                            }
                                            return true;
                                        },
                                    },
                                })}
                            />
                        </div>
                    </Column>
                </Row>
                <Row>
                    <button
                        type="button"
                        className="btn btn-primary"
                        onClick={(e) => {
                            cancelAdd();
                        }}
                    >
                        Cancel
                    </button>

                    <button
                        type="button"
                        className="btn btn-secondary pull-right"
                        onClick={async (e) => {
                            await addEditShippingAddress();
                        }}
                    >
                        {isEditing === true && isAdding === false ? "Save" : "Add"}
                    </button>
                </Row>
            </div>
        );
    };

    return <div>{renderAddEditRow()}</div>;
};
