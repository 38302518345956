import React, { useContext } from "react";
import { SectionTitle } from "../../core/SectionTitle";
import { Translation } from "../../core/Translation";
import { Language } from "../../infrastructure/Languages";

export const EditEpacConnectContact = ({
  register,
  getErrorMessage,
  errors
}) => {

  const language = useContext(Language);

  return (
    <div>
      <div className="input-validation">
        <SectionTitle required>
          <Translation id="8eb7936c-b737-4f03-b8d8-e282d9f43c6a" defaultMessage="First Name" category='Label' />
        </SectionTitle>
        <input
          className={"control textbox" + (errors && errors.epacConnect ? " error" : "")}
          placeholder={language.translateLabel('8eb7936c-b737-4f03-b8d8-e282d9f43c6a', 'First Name', 'Label')}
          {...register('epacConnectData.firstName', {
            required: {
              value: true,
              message: "First name is required"
            }
          })
          }
        />
        {getErrorMessage(errors, "epacConnectData.firstName") && <span className="error-message">{getErrorMessage(errors, "epacConnectData.firstName")?.message}</span>}
      </div>
      <div className="input-validation">
        <SectionTitle required>
          <Translation id="cedc02f5-9290-4fa0-9107-c052919a958c" defaultMessage="Last Name" category='Label' />
        </SectionTitle>
        <input
          className={"control textbox" + (errors && errors.epacConnect ? " error" : "")}
          placeholder={language.translateLabel('cedc02f5-9290-4fa0-9107-c052919a958c', 'Last Name', 'Label')}
          {...register('epacConnectData.lastName', {
            required: {
              value: true,
              message: "Last name is required"
            }
          })
          }
        />
        {getErrorMessage(errors, "epacConnectData.lastName") && <span className="error-message">{getErrorMessage(errors, "epacConnectData.lastName")?.message}</span>}
      </div>
      <div className="input-validation">
        <SectionTitle required>
          <Translation id="15b53b85-df5e-46db-a112-10950c5dfe37" defaultMessage={"Email"} category='Label' />
        </SectionTitle>
        <input
          className={"control textbox" + (errors && errors.epacConnect ? " error" : "")}
          placeholder={language.translateLabel("15b53b85-df5e-46db-a112-10950c5dfe37", "Email", 'Label')}
          {
          ...register("epacConnectData.emailAddress",
            {
              required: { value: true, message: "E-mail address is required" },
              pattern: {
                value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                message: "E-mail address is not correctly formatted"
              }
            })
          }
        />
        {getErrorMessage(errors, "epacConnectData.emailAddress") && <span className="error-message">{getErrorMessage(errors, "epacConnectData.emailAddress")?.message}</span>}
      </div>
    </div>
  );
};
