import React, { useState, useEffect, useContext } from "react";
import { Row } from "../../core/Row";
import { Column } from "../../core/Column";
import { SectionTitle } from "../../core/SectionTitle";
import { Money } from "./Money";
import { FormatProvider } from "../../core/offer/FormatProvider";
import { Translation } from "../../core/Translation";
import { ClickableImage } from "../ClickableImage";
import { Language } from "../../infrastructure/Languages";

import "./SkuEditor.css";

export class Sku {
    constructor(quantity, unitPrice, description, poNumber) {
        this.quantity = quantity;
        this.unitPrice = unitPrice;
        this.description = description;
        this.poNumber = poNumber;
    }

    get totalPrice() {
        return this.quantity * this.unitPrice;
    }
}

export const SkuEditor = ({
    offerInfo,
    register,
    skus,
    orderPlacementApi,
    errors,
    getErrorMessage,
    setValue,
    append,
    watch,
    control,
    clearErrors,
    getValues,
    setError,
    trigger,
    trackEvent,
    analyticsMetadata,
    orderConfiguration
}) => {

    const language = useContext(Language)
    const [initialized, setInitialized] = useState(false);
    const isTestRoll = offerInfo?.testRollType;

    const key = offerInfo.key;
    const restrictions =
        offerInfo && offerInfo.checkoutDetails && offerInfo.checkoutDetails.checkoutRestrictions
            ? offerInfo.checkoutDetails.checkoutRestrictions
            : null;

    const offerHasOutsourcingCost =
        !offerInfo.isPressProof &&
        offerInfo.packagingFeature &&
        offerInfo.packagingFeature.outsourcedFeatures &&
        offerInfo.packagingFeature.outsourcedFeatures.length > 0;

    const isReorder = !!offerInfo.reorderDetails;
    const priceColumnWidth = isReorder ? 6 : 4; // in case of reorder there is a column more showing thumbnail, so we reduce price columns width

    const paymentsDisabledOnRegion = orderConfiguration ? orderConfiguration.paymentsDisabled : false;

    const distribute = (quantity, skuCount) => {
        let result = [];
        let divident = Math.floor(quantity / skuCount);
        let remainder = quantity % skuCount;
        for (var i = 0; i < skuCount; i++) {
            result.push(i < remainder ? divident + 1 : divident);
        }
        return result;
    };

    const getQuantityBreaks = (allQuantityBreaks) => {
        let result = [];
        allQuantityBreaks.forEach((b) => {
            let quantityBreak = {
                quantity: parseInt(b.quantityBreak),
                unitPrice: {
                    amount: offerHasOutsourcingCost ? b.outsourcing.totalUnitPrice.amount : b.price.unitPrice.amount,
                    currency: b.price.unitPrice.currency,
                },
            };
            result.push(quantityBreak);
        });
        return result;
    };

    const createSkuCollection = (quantityBreak) => {
        let result = [];
        let skuQuantities = distribute(quantityBreak.quantity, offerInfo.skuCount);
        let i = 0;

        skuQuantities.forEach((skuQuantity) => {
            i++;
            let item = new Sku(skuQuantity, quantityBreak.unitPrice.amount, "", null);
            result.push(item);
        });

        return result;
    };

    const [quantityBreaks, setQuantityBreaks] = useState(function init() {
        return getQuantityBreaks(offerInfo.calculationBreakdown);
    });

    const [activeQuantityBreak, setActiveQuantityBreak] = useState(function init() {
        quantityBreaks[0].unitPrice.amount = parseFloat(quantityBreaks[0].unitPrice.amount);
        return quantityBreaks[0];
    });



    const getTotalQuantityForAllLineItems = (skusToSum) => {
        var total = 0;
        if (skusToSum) {
            total = getTotalForSkus(skusToSum);
        } else {
            total = getTotalForSkus(getValues("skuLineItems"));
        }
        console.log("getTotalQuantityForAllLineItems total", total);

        return total;
    };

    const recalculateTotal = (skusToSum) => {
        var total = getTotalQuantityForAllLineItems(skusToSum);

        setValue("skuTotal", total);

        if (parseInt(total) < parseInt(quantityBreaks[0].quantity) && !isTestRoll) {
            setError("badTotal", {
                type: "custom",
                message: `${language.translateLabel('cd1be1e0-b9b6-4224-9639-72126babef8f', 'The total quantity of all SKUs must exceed', 'Label')} ${quantityBreaks[0].quantity}.`
            });
        } else {
            clearErrors("badTotal");
        }
    };

    const setErrorIfBelowAbsoluteMinimum = (total) => {
        if (parseInt(total) < parseInt(quantityBreaks[0].quantity) && !isTestRoll) {
            setError("badTotal", {
                type: "custom",
                message: `${language.translateLabel('cd1be1e0-b9b6-4224-9639-72126babef8f', 'The total quantity of all SKUs must exceed', 'Label')} ${quantityBreaks[0].quantity}.`
            });
        } else {
            clearErrors("badTotal");
        }
    };


    const onSkuLineItemQuantityChanged = async (quantity, index) => {
        var total = getTotalQuantityForAllLineItems();
        setValue("skuTotal", total);
        setErrorIfBelowAbsoluteMinimum(total);
        await trigger(`skuLineItems.${index}.quantity`);
        //setErrorIfSkuBelowMin();
        trackEvent({
            event: "ordering_tool_interactions",
            eventProps: {
                step: "placement_2",
                product_category: analyticsMetadata.industryName,
                product_sub_category: analyticsMetadata.industryCategoryName,
                selection: `sku_${index}_quantity`,
                element: quantity,
            },
            userId: analyticsMetadata?.visitorId,
        });
    };

    const getTotalForSkus = (skusToSum) => {
        var skuTotal = 0;
        if (skusToSum) {
            skusToSum.forEach((sku) => {
                skuTotal += parseInt(sku.quantity) ? parseInt(sku.quantity) : 0;
            });
        }
        return skuTotal;
    };

    useEffect(() => {
        if (initialized) {
            return;
        }
        console.group("%c SkuEditor initialize START", "color: #ff7f5d");

        //Init skus checking if data has been already entered before
        (!skus || skus.length === 0) ? initSkusPristine() : initSkusDirty();

        console.log("%c SkuEditor initialize END", "color: #ff7f5d");
        console.groupEnd();
        setInitialized(true);
    }, []);


    const initSkusPristine = () => {
        console.log("skus form is pristine");
        var quantityBreakList = getQuantityBreaks(offerInfo.calculationBreakdown);

        if (isTestRoll)
            initTestRollSku();
        else if (isReorder)
            initReorderSkus(quantityBreakList);
        else
            initSkus(quantityBreakList);

        setValue("skuTotal", 0);
    };

    const initSkusDirty = () => {
        console.log("skus form is dirty", skus);
        if (isReorder)
            assignThumbnailToSkus();

        isTestRoll ? setValue("skuUnitPrice", getTotalPrice()) : setValue("skuUnitPrice", parseFloat(getValues("skuLineItems.0.unitPrice")));
        recalculateTotal(skus);
    };

    const initTestRollSku = () => {
        append({
            description: "Test Roll",
            quantity: 1,
            unitPrice: getTotalPrice()
        })
        setValue("skuUnitPrice", getTotalPrice());
    };

    const initReorderSkus = (quantityBreakList) => {
        var skuLineItems = getReorderSkuList(quantityBreakList[0].unitPrice.amount);
        append(skuLineItems);
        setValue("skuUnitPrice", parseFloat(quantityBreakList[0].unitPrice.amount));
    };

    const initSkus = (quantityBreakList) => {
        var skuLineItems = [];
        var collection = createSkuCollection(quantityBreakList[0]);
        collection.forEach((sku, idx) => {
            skuLineItems.push({
                description: "",
                quantity: null,
                unitPrice: sku.unitPrice
            });
        });
        append(skuLineItems);
        setValue("skuUnitPrice", parseFloat(quantityBreakList[0].unitPrice.amount));
        recalculateTotal(skuLineItems);
    };

    const assignThumbnailToSkus = () => {
        skus.forEach((sku) => {
            var reorderBaseSku = offerInfo.reorderDetails.skus.find(x => x.approvedSku == sku.approvedSku);
            if (reorderBaseSku)
                sku.thumbnail = reorderBaseSku.thumbnail;
        });
    }

    const getMatchingQuantityBreak = (qty) => {
        let result = quantityBreaks[0];
        quantityBreaks.forEach((br) => {
            if (qty >= br.quantity) {
                result = br;
            }
        });
        return result;
    };

    const getCurrency = () => quantityBreaks[0].unitPrice.currency;
    const getMaterialLength = () => offerInfo.calculationBreakdown[0]?.filmLength?.value;
    const getLengthUOM = () => offerInfo.calculationBreakdown[0]?.filmLength?.unit ? offerInfo.calculationBreakdown[0]?.filmLength?.unit : " ft";
    const getTotalPrice = () => offerInfo.calculationBreakdown[0].price.totalPrice.amount;


    const getReorderSkuList = (unitPrice) => {
        var list = [];
        if (offerInfo.reorderDetails.skus) {
            offerInfo.reorderDetails.skus.forEach((sku) => {
                list.push({
                    approvedSkuId: sku.approvedSkuId,
                    description: sku.description,
                    quantity: null,
                    unitPrice: unitPrice,
                    thumbnail: sku.thumbnail
                })
            });
        }
        return list;
    }

    const skuQuantityTotalWatch = watch("skuTotal");
    const skuUnitPriceWatch = watch("skuUnitPrice");
    const skuTotals = getValues("skuTotal");

    useEffect(() => {
        if (!initialized) {
            return;
        }
        console.log("Whoa, sku total changed", skuTotals);
        var parsedTotals = parseInt(getValues("skuTotal"));

        var newMatchingQuantityBreak = getMatchingQuantityBreak(parsedTotals);
        console.log(
            "old quantity break, new quantity break",
            activeQuantityBreak.quantity,
            newMatchingQuantityBreak.quantity
        );
        if (newMatchingQuantityBreak.quantity !== activeQuantityBreak.quantity) {
            console.log(
                "quantity now exceeds the active quantity break, so here's the new one",
                newMatchingQuantityBreak
            );
            for (let index in skus) {
                setValue(`skuLineItems.${index}.unitPrice`, parseFloat(newMatchingQuantityBreak.unitPrice.amount));
            }
            setValue("skuUnitPrice", parseFloat(newMatchingQuantityBreak.unitPrice.amount));
            setActiveQuantityBreak(newMatchingQuantityBreak);
        }
    }, [skuTotals]);

    const getLineItemTotalPrice = (unitPrice, quantity) => {
        var total = (parseFloat(unitPrice) * parseInt(quantity)).toFixed(2);
        return isNaN(total) ? 0 : total;
    };

    return (
        <div className="skuEditor">
            <input type="hidden" {...register("skuUnitPrice")} />
            {initialized && skus && (
                <div>

                    <Row className="title-row">
                        <Column width={3} className="description">
                            <SectionTitle
                                offerKey={key}
                                required
                                cms="sku-description"
                                orderPlacementApi={orderPlacementApi}
                                imageUrl="/images/popup/default_popup.png"
                                onShowHelp={() => {
                                    // gaContext.sendHelpClick("SKU Description");
                                }}
                            >
                                <Translation
                                    id="40a94643-b358-42d9-b832-4ae33401bc90"
                                    defaultMessage="SKU Description"
                                    category="Label"
                                />
                            </SectionTitle>
                        </Column>
                        {
                            isReorder &&
                            <div className="empty-column">
                                &nbsp;  {/*Add an empty column in order to keep the whole table aligned when thumbnail image is added to the row*/}
                            </div>
                        }
                        {
                            isTestRoll ?
                                <Column width={4} className="materialLength">
                                    <SectionTitle cms="material-length" imageUrl="/images/popup/default_popup.png">
                                        <Translation id='42664f74-d7f0-403f-9497-46d973a9c649' defaultMessage='Material Length' category='Label' />
                                    </SectionTitle>
                                </Column>
                                :
                                <Column width={priceColumnWidth} className="unitPrice">
                                    <SectionTitle cms="unit-price" imageUrl="/images/popup/default_popup.png">
                                        <Translation
                                            id="5e4f786d-029d-4464-8911-dcb3db5bab93"
                                            defaultMessage="Unit Price"
                                            category="Label"
                                        />
                                    </SectionTitle>
                                </Column>
                        }
                        <Column width={6} className="quantity">
                            <SectionTitle
                                required
                                offerKey={key}
                                cms="quantity"
                                orderPlacementApi={orderPlacementApi}
                                imageUrl="/images/popup/default_popup.png"
                                onShowHelp={() => {
                                    // gaContext.sendHelpClick("Quantity");
                                }}
                            >
                                <Translation
                                    id="6c6df362-bf55-4248-8e0a-4af3eb5c99d4"
                                    defaultMessage="Quantity"
                                    category="Label"
                                />
                            </SectionTitle>
                        </Column>
                        <Column width={priceColumnWidth} className="price">
                            <SectionTitle
                                offerKey={key}
                                cms="price"
                                orderPlacementApi={orderPlacementApi}
                                imageUrl="/images/popup/default_popup.png"
                                onShowHelp={() => {
                                    // gaContext.sendHelpClick("Total Price");
                                }}
                            >
                                <Translation
                                    id="3598c25f-9b19-40e3-bf90-1c5c81f222cd"
                                    defaultMessage="Price"
                                    category="Label"
                                />
                            </SectionTitle>
                        </Column>
                    </Row>
                    {skus.map((field, index) => {
                        return (
                            <Row className="sku-row" key={"sku-row" + index}>
                                <Column width={3} className="description">
                                    <div className="input-validation">
                                        <div className="sku-description">
                                            <input
                                                data-testid={"order-placement-sku-breakdown-description"}
                                                key={field.id}
                                                className={`control textbox ${getErrorMessage(errors, `skuLineItems.${index}.description`)?.message
                                                    ? "error"
                                                    : null
                                                    }`}
                                                //ref={register}
                                                disabled={isTestRoll}
                                                placeholder={isTestRoll ? null : language.translateLabel('40a94643-b358-42d9-b832-4ae33401bc90', 'Sku description', 'Label')}
                                                {...register(`skuLineItems.${index}.description`, {
                                                    required: {
                                                        value: true,
                                                        message: language.translateLabel('ddef01f4-39c8-41fb-b74b-6f7f61f1122b', 'Sku description is required', 'Label'),
                                                    },
                                                    maxLength: {
                                                        value: 100,
                                                        message: language.translateLabel('9bd60021-c9a6-4f16-a257-d7ff210818c7', 'Sku length cannot exceed {0} characters', 'Label', { 0: 100 }),
                                                    },
                                                    pattern: {
                                                        value: /^[^\\|/|:|*|?|"|<|>||(|)|^|||&|%|!|@|#|$|(]*$/,
                                                        message: `${language.translateLabel('afc2417b-0866-4a53-8022-3aebe73acbdb', 'Sku descriptions cannot contain the following characters', 'Label')}: ! @ # $ % ^ & * ( ) / : * ? " < > |`,
                                                    },
                                                    onBlur: (e) => {
                                                        trackEvent({
                                                            event: "ordering_tool_interactions",
                                                            eventProps: {
                                                                step: "placement_2",
                                                                product_category: analyticsMetadata.industryName,
                                                                product_sub_category:
                                                                    analyticsMetadata.industryCategoryName,
                                                                selection: `sku_${index}_description`,
                                                                element: e.target.value,
                                                            },
                                                            userId: analyticsMetadata?.visitorId,
                                                        });
                                                    },
                                                })}
                                            />
                                        </div>
                                        {getErrorMessage(errors, `skuLineItems.${index}.description`) && (
                                            <span className="error-message">
                                                {getErrorMessage(errors, `skuLineItems.${index}.description`)?.message}
                                            </span>
                                        )}
                                    </div>
                                </Column>
                                {
                                    isReorder &&
                                    <ClickableImage src={field.thumbnail} />
                                }
                                {
                                    isTestRoll ?
                                        <Column width={4} className="materialLength">
                                            <span className="amount color-grey">
                                                {FormatProvider.FormatNumberWithCommas(getMaterialLength())}
                                                {getLengthUOM()}
                                            </span>
                                        </Column>
                                        :
                                        <Column width={priceColumnWidth} className="unitPrice">
                                            {/* <Money currency={getCurrency()} amount={field.unitPrice.toFixed(4)} /> */}
                                            <div className="money">
                                                <span className="currency color-light-grey">{getCurrency()}</span>
                                                <span className="amount color-grey">{skuUnitPriceWatch}</span>
                                                <input
                                                    type="hidden"
                                                    className="amount color-grey"
                                                    readOnly
                                                    {...register(`skuLineItems.${index}.unitPrice`)}
                                                />
                                            </div>
                                        </Column>
                                }
                                <Column width={6} className="quantity">
                                    <div className="input-validation">
                                        <input
                                            type="number"
                                            onWheel={e => {
                                                e.target.blur();
                                            }}
                                            data-testid={"order-placement-sku-breakdown-quantity"}
                                            disabled={isTestRoll}
                                            className={`control textbox ${getErrorMessage(errors, `skuLineItems.${index}.quantity`)?.message
                                                ? "error"
                                                : null
                                                }`}
                                            {...register(`skuLineItems.${index}.quantity`, {
                                                required: {
                                                    value: true,
                                                    message: language.translateLabel('c5112ce9-64cd-4af9-884f-ceddd2d74cb6', 'Sku quantity is required and must be greater than zero', 'Label'),
                                                },
                                                maxLength: {
                                                    value: 10,
                                                    message: language.translateLabel('8bb0625a-8961-418f-bee9-a06565d0094f', 'Sku quantity cannot exceed {0} digits', 'Label', { 0: 10 }),
                                                },
                                                pattern: {
                                                    value: /^\d+$/,
                                                    message: language.translateLabel('fca1895b-d531-46a6-a192-6655f6a3ef9c', 'Sku quantity must be numeric', 'Label'),
                                                },
                                                onChange: async (e) => await onSkuLineItemQuantityChanged(e.target.value, index),
                                                validate: {
                                                    positive: (v) => {
                                                        if (v > 0) {
                                                            return true;
                                                        }
                                                        return language.translateLabel('dd39c7d6-d785-4efc-9811-ee7990b477ee', 'Must be greater than zero', 'Label')
                                                    },
                                                    quantityCheck: (v) => {
                                                        if (
                                                            restrictions &&
                                                            restrictions.minSkuQuantity &&
                                                            v < restrictions.minSkuQuantity
                                                        ) {
                                                            return `${language.translateLabel('e927909e-06c5-45c5-8a34-d55cc61e1cb5', 'SKU quantity cannot be be less than', 'Label')} ${restrictions.minSkuQuantity}`;
                                                        }

                                                        if (
                                                            restrictions &&
                                                            restrictions.maxSkuQuantity &&
                                                            v > restrictions.maxSkuQuantity
                                                        ) {
                                                            return `${language.translateLabel('08d15f77-49c2-4ee1-8df3-c9ea0692e80d', 'SKU quantity cannot be greater than', 'Label')} ${restrictions.maxSkuQuantity}`;
                                                        }
                                                        return true;
                                                    },
                                                }
                                            })}
                                        />
                                        {getErrorMessage(errors, `skuLineItems.${index}.quantity`) && (
                                            <span className="error-message">
                                                {getErrorMessage(errors, `skuLineItems.${index}.quantity`)?.message}
                                            </span>
                                        )}
                                    </div>
                                </Column>
                                <Column width={priceColumnWidth} className="price">
                                    <Money
                                        currency={getCurrency()}
                                        amount={FormatProvider.FormatDecimalWithCommas(
                                            getLineItemTotalPrice(
                                                getValues(`skuLineItems.${index}.unitPrice`),
                                                getValues(`skuLineItems.${index}.quantity`),
                                                2
                                            ), 2)}
                                    />
                                </Column>
                            </Row>
                        );
                    })}
                    {
                        getErrorMessage(errors, "badTotal") && (
                            <Row>
                                <Column width={3} className="description">
                                    &nbsp;
                                </Column>
                                <Column width={4} className="description">
                                    &nbsp;
                                </Column>
                                <Column width={6} className="description">
                                    <div className="error-message">{getErrorMessage(errors, "badTotal")?.message}</div>
                                </Column>
                                <Column width={4} className="description">
                                    &nbsp;
                                </Column>
                            </Row>
                        )}
                    {
                        !isTestRoll &&
                        <Row className="totals-row">
                            <Column width={3} className="description">
                                &nbsp;
                            </Column>
                            {
                                isReorder &&
                                <div className="empty-column">
                                    &nbsp;  {/*Add an empty column in order to keep the whole table aligned when thumbnail image is added to the row*/}
                                </div>
                            }
                            <Column width={priceColumnWidth} className="unitPrice">
                                <span>
                                    <Translation
                                        id="45178e09-c110-485f-8f1b-0b16e7331cd8"
                                        defaultMessage="Total"
                                        category="Label"
                                    />
                                </span>
                            </Column>
                            <Column width={6} className="quantity">
                                <span className="amount color-grey">
                                    {FormatProvider.FormatNumberWithCommas(skuQuantityTotalWatch)}
                                </span>
                            </Column>
                            <Column width={priceColumnWidth} className="price">
                                <Money
                                    currency={getCurrency()}
                                    amount={FormatProvider.FormatDecimalWithCommas(
                                        (parseFloat(skuUnitPriceWatch) * parseInt(skuQuantityTotalWatch)).toFixed(2),
                                        2
                                    )}
                                />
                            </Column>
                        </Row>
                    }
                    {
                        paymentsDisabledOnRegion &&
                        <div>
                            <Row />
                            <Row />
                            <i>
                                <Translation id='c7e48e68-12b5-439d-b32b-f43be33bc605' defaultMessage='Overage, Shipping, and VAT are not included in the Subtotal. A Prepayment request will be sent after artwork upload' category='Label' />
                            </i>
                        </div>
                    }
                </div>
            )}
        </div>
    );
};
